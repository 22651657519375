import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import sp from "./locales/sp.json";
import gu from "./locales/gu.json";

const languages = ["en", "sp", "gu"];

const storedLanguage = localStorage.getItem("language");
const languageToUse =
  storedLanguage && languages.includes(storedLanguage) ? storedLanguage : "en";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    sp: { translation: sp },
    gu: { translation: gu },
  },
  lng: languageToUse,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export { languages };
export default i18n;
