import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import OrderAddresses from "../../../shared/components/order-address/OrderAddress";
import OrderProducts from "../../../shared/components/order-products/OrderProducts";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import helperService from "../../../services/helperService";
import { toast } from "react-toastify";
import apiService from "../../../services/apiService";
import { useSelector } from "react-redux";
import Breadcrumb from "../../../shared/components/breadcrumb/Breadcrumb";
import NoDataPage from "../../../shared/components/no-data-page/NoDataPage";
interface Address {
  address_id: number;
  address_line1: string;
  address_line2?: string; // Optional field if not always present
  city: string;
  country: string;
  created_at: string;
  default_billing: boolean;
  default_shipping: boolean;
  deleted_at?: string | null; // Could be null
  name: string;
  pincode: string;
  state: string;
  updated_at: string;
  user_id: number;
}

const OrderDetails: React.FC = () => {
  const [loadingOrderInfo, setLoadingOrderInfo] = useState<boolean>(false);
  const [showPrice, setShowPrice] = useState<boolean>(false);
  const { t } = useTranslation();
  const [billingAddress, setBillingAddress] = useState<Address | null>(null);
  const [shippingAddress, setShippingAddress] = useState<Address | null>(null);
  const [orderNumber, setOrderNumber] = useState<string>("");
  const [orderDateTime, setOrderDateTime] = useState<string>("");
  const [orderStatus, setOrderStatus] = useState<string>("");
  const { orderId } = useParams<{ orderId?: string }>();
  const [products, setProducts] = useState<any[]>([]);
  const { config } = useSelector((state: any) => state.config);
  const [noDataPage, setNoDataPage] = useState<boolean>(false);
  // Define the type for Product
  const [breadcrumb] = useState<{ name: string; path: string }[]>([
    { name: t("common.HOME"), path: "/" },
    { name: t("common.ORDERS"), path: "/orders" },
    { name: t("common.ORDERS_DETAILS"), path: "" },
  ]);
  // Order Details on component mount
  useEffect(() => {
    setLoadingOrderInfo(true);
    if (Object.keys(config).length !== 0) {
      setShowPrice(helperService.checkConfigByKey(config, "show_price"));
      fetchOrderDetails();
    }
  }, [config]);

  //call api to fetch particular order details and send data to child component
  const fetchOrderDetails = async () => {
    setLoadingOrderInfo(true);
    try {
      const res: any = await apiService.getOrderDetails(orderId);
      setBillingAddress(res.data.billingAddress);
      setShippingAddress(res.data.shippingAddress);
      setOrderNumber(res.data.orderDetails.order_no);
      const dateTime = helperService.formatDate(
        res.data.orderDetails.created_at,
      );
      setOrderDateTime(dateTime);
      setOrderStatus(
        res.data.orderDetails.order_status?.charAt(0).toUpperCase() +
          res.data.orderDetails.order_status?.slice(1),
      );
      setProducts(res.data.orderItems);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
      setNoDataPage(true);
    } finally {
      setLoadingOrderInfo(false);
    }
  };

  return (
    <div className="flex h-full max-h-[calc(100vh-100.3px)] flex-col overflow-y-auto">
      <div className="items-left flex flex-col lg:flex-row lg:space-y-0">
        <h2 className="text-left text-xl font-bold lg:text-left lg:text-2xl">
          {t("common.ORDERS_DETAILS")}
        </h2>
      </div>

      {/* Breadcrumbs */}
      {/* Conditionally Render Breadcrumb */}
      <div className="mb-6 mt-2 pl-1">
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>
      <div className="mb-1">
        <hr />
      </div>
      <div className="h-full min-h-screen w-full">
        {loadingOrderInfo ? (
          <div
            className="flex items-center justify-center pt-64"
            data-testid="clip-loader"
          >
            <ClipLoader color="#0000ff" loading={loadingOrderInfo} size={50} />
          </div>
        ) : (
          <>
            <div className="flex h-full flex-col">
              {noDataPage ? (
                <NoDataPage
                  title={t("common.NO_ORDER_FOUND")}
                  message={t("cart.MY_CART_EMPTY_MESSAGE")}
                  buttonText={t("cart.MY_CART_GO_TO_PRODUCT_PAGE")}
                  link="/category/root"
                />
              ) : (
                <>
                  <div className="flex justify-between">
                    <div className="">
                      <h3 className="text-xl font-semibold">
                        {t("common.ORDER_NUMBER")}: {orderNumber}
                        <span
                          className={`mx-2 font-semibold ${helperService.getStatusColor(orderStatus)}`}
                        >
                          |
                        </span>
                        <span
                          className={`font-semibold ${helperService.getStatusColor(orderStatus)}`}
                        >
                          {orderStatus}
                        </span>
                      </h3>
                      <div className="text-sm font-semibold">
                        {t("common.DATE_TIME")}: {orderDateTime}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    {/* Products/items details of particular order */}
                    <OrderProducts products={products} showPrice={showPrice} />
                  </div>
                  <div className="space-y-8">
                    {/* Billing Address of particular order*/}
                    <div className="pt-8">
                      <OrderAddresses
                        title={t("common.BILLING_ADDRESS")}
                        address={billingAddress}
                        backgroundColor="bg-background-grey-100"
                      />
                    </div>
                    {/* Shipping Address of particular order */}
                    <OrderAddresses
                      title={t("common.SHIPPING_ADDRESS")}
                      address={shippingAddress}
                      backgroundColor="bg-background-grey-100"
                    />
                  </div>
                  <div className="mt-auto">
                    <Link
                      to="/contact-us"
                      className="mt-8 block w-full rounded-md border border-background-grey-200 bg-offwhite py-4 text-center font-semibold text-offblack transition-colors duration-200 hover:bg-primary-100"
                    >
                      {t("common.NEED_HELP")}
                    </Link>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
