import { Outlet } from "react-router-dom";
import Header from "../../../pages/header/Header";
import Sidebar from "../../../pages/sidebar/Sidebar";

const Layout = () => {
  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <Sidebar />

      <div className="flex flex-1 flex-col">
        {/* Header */}
        <Header />

        {/* Main Content */}
        <div className="mx-6 my-2 max-md:mx-2 flex-grow">
          <Outlet />
        </div>

        {/* Footer */}
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Layout;
