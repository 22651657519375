const Map = ({ url }: any) => {
  return (
    <section className="max-h-full min-h-96 w-full flex-1 overflow-hidden rounded-lg border-4 md:flex-1 lg:flex-[1.6]">
      <iframe
        className="max-h-full min-h-full w-full max-lg:min-h-96"
        src={url}
        title="Google maps"
        style={{ border: "0" }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
};

export default Map;
