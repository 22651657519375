import React, { useState } from "react";
import { MagnifyingGlassIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the default form submission behavior
      if (searchText.trim()) {
        // Navigate to the search page with the search text as a query parameter
        navigate(`/search?searchText=${searchText.trim()}`);
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-between bg-white p-4 shadow">
        {/* Search bar */}
        <div className="flex items-center overflow-hidden rounded-md border border-gray-300 md:ml-10">
          <MagnifyingGlassIcon
            className="ml-2 text-gray-400"
            width={20}
            height={20}
          />
          <input
            type="search"
            placeholder={t("common.SEARCH")}
            className="max-w-64 border-none px-4 py-2 outline-none focus:border-transparent focus:outline-none focus:ring-0"
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>

        {/* Phone number with icon */}
        <div className="flex items-center space-x-4 pl-2">
          {/* User avatar */}
          <Link to={"my-account"}>
            <div className="flex h-10 w-10 items-center justify-center rounded-md border border-blue-400 bg-blue-100 p-1">
              <UserIcon
                width={20}
                height={20}
                className="text-primary"
              ></UserIcon>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
