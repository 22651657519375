import React, { useEffect, useState } from "react";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import helperService from "../../services/helperService";
import Breadcrumb from "../../shared/components/breadcrumb/Breadcrumb";
import { ClipLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams, useNavigate } from "react-router-dom";
import {
  PAGINATION_CONSTANTS,
  TEXT_CONSTANTS,
} from "../../shared/constants/globalConstants";
import { useDispatch, useSelector } from "react-redux";
import { updateCartLength } from "../../redux/slices/cartSlice";
import { useTranslation } from "react-i18next";

const Product: React.FC = () => {
  const { t } = useTranslation();
  const { productId } = useParams<{ productId: string }>(); // Get productId from URL params
  const [products, setProducts] = useState<any[]>([]);
  const [cartItems, setCartItems] = useState<any[]>([]); // State to manage cart items
  const [loading, setLoading] = useState<boolean>(false);
  const [addToCartLoading, setAddToCartLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(PAGINATION_CONSTANTS.PAGE);
  const per_page = PAGINATION_CONSTANTS.PER_PAGE; // Number of products per page
  const [breadcrumb, setBreadcrumb] = useState<[]>([]);
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [productTitle, setProductTitle] = useState<string>("All products");
  const [endOfScrollData, setEndOfScrollData] = useState<boolean>(false);
  const { config } = useSelector((state: any) => state.config);
  const isConfigLoaded = Object.keys(config).length !== 0;
  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
    setBreadcrumbData();
  }, []);

  useEffect(() => {
    if (scrolled) {
      const loader = false;
      // Fetch categories only if scrolled
      fetchProducts(loader); // Will use the updated 'page' from state
    }
  }, [page]);
  const dispatch = useDispatch();
  const fetchProducts = async (loader?: boolean) => {
    loader === false ? setLoading(false) : setLoading(true);
    try {
      const res: any = await apiService.getProducts(productId, page, per_page);
      const { products } = res.data;
      products.length < per_page && setEndOfScrollData(true);
      setProductTitle(res.data.name);
      setProducts((prev: any) => [...prev, ...products]);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const setBreadcrumbData = async () => {
    setLoading(true);
    try {
      const res: any = await apiService.getBreadcrumbData(productId);
      const breadcrumbData = res.data;
      breadcrumbData.unshift({ category_id: "root", name: "Products" });
      setBreadcrumb(breadcrumbData);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (
    index: number,
    value: number,
    isAdd: boolean,
  ) => {
    const updatedProducts = [...products];
    const product = updatedProducts[index];

    const lowerMultiple =
      Math.floor(value / product.order_quantity) * product.order_quantity;
    const higherMultiple =
      Math.ceil(value / product.order_quantity) * product.order_quantity;

    if (value % product.order_quantity !== 0) {
      if (isAdd) {
        product.quantity = lowerMultiple;
      } else product.quantity = higherMultiple;
    } else {
      product.quantity = value;
    }

    setProducts(updatedProducts);

    const updatedCart = updatedProducts.filter(
      (product) => product.quantity > 0,
    );
    setCartItems(updatedCart);
  };

  const handelInputQuantityChange = (index: number, value: number) => {
    const updatedProducts = [...products];
    const product = updatedProducts[index];

    product.quantity = value;
    setProducts(updatedProducts);

    const updatedCart = updatedProducts.filter(
      (product) => product.quantity > 0,
    );
    setCartItems(updatedCart);
  };

  // API call to add items to cart
  const handleAddToCart = async (e: any) => {
    e.preventDefault();
    setAddToCartLoading(true);
    if (cartItems.length === 0) {
      toast.error(t("toast.INVALID_PRODUCT_QUANTITY"));
      setAddToCartLoading(false);
      return;
    }
    try {
      const payload = cartItems.map((item) => {
        return {
          product_id: item.product_id,
          product_name: item.name,
          quantity: item.quantity,
        };
      });
      const res: any = await apiService.addToCart(payload);
      setCartItems([]);

      setProducts((prevProducts) =>
        prevProducts.map((item) => ({
          ...item,
          quantity: 0,
        })),
      );
      dispatch(updateCartLength({ length: res.data.cart.cart_items.length }));
      toast.success(res.message);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setAddToCartLoading(false);
    }
  };

  // Fetch more data on scroll
  const fetchMoreData = async () => {
    setScrolled(true);
    setPage((prevPage) => prevPage + 1);
  };

  const handleBreadcrumbClick = (item: any) => {
    helperService.handleBreadcrumbClick(item, navigate);
  };

  return (
    <div className="max-h-[calc(100vh-100.3px)] overflow-y-auto rounded-md bg-white">
      {loading ? (
        <div className="flex items-center justify-center pt-64">
          <ClipLoader color="#0000ff" loading={loading} size={50} />
        </div>
      ) : (
        <form onSubmit={handleAddToCart}>
          <div className="mb-4 flex flex-col items-center justify-between space-y-4 lg:flex-row lg:space-y-0">
            <h2 className="text-center text-xl font-bold lg:text-left lg:text-2xl">
              {productTitle}
            </h2>
            <button
              className="teal-button flex w-full min-w-[150px] items-center justify-center rounded-md px-4 py-2 text-white lg:w-auto"
              type="submit"
              disabled={addToCartLoading}
            >
              {addToCartLoading ? (
                <ClipLoader size={20} color="#ffffff" />
              ) : (
                <span className="mr-2">+ {t("common.ADD_TO_CART")}</span>
              )}
            </button>
          </div>

          <div className="mt-2">
            {breadcrumb.length > 1 && (
              <Breadcrumb
                breadcrumb={breadcrumb}
                onClick={handleBreadcrumbClick}
              />
            )}
          </div>

          <div className="p-1">
            <hr />
          </div>

          <InfiniteScroll
            dataLength={products.length}
            next={fetchMoreData}
            hasMore={!endOfScrollData}
            loader={
              <div className="my-4 flex h-64 items-center justify-center">
                <ClipLoader color="#0000ff" loading={true} size={30} />
              </div>
            }
            height="70vh"
            scrollThreshold={0.9}
            endMessage={
              <div className="my-4 flex h-64 items-center justify-center">
                <p className="text-center font-medium text-gray-600">
                  {products.length > 0
                    ? t("common.END_OF_DATA_MESSAGE")
                    : t("common.NO_DATA_AVAILABLE")}
                </p>
              </div>
            }
          >
            {products.map((product, index) => (
              <div
                className="mt-4 flex flex-col rounded-md border sm:pr-2 md:pr-4 lg:flex-row lg:pr-4"
                key={product.product_id}
              >
                <div className="flex items-center justify-center rounded-s bg-blue-100 p-4 font-bold text-blue-600 lg:w-12 lg:justify-start">
                  {index + 1}
                </div>

                <div className="flex w-full flex-col items-center justify-between p-4 md:flex-row lg:items-start">
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-4 max-md:flex-col max-md:items-start">
                      <p className="font-semibold lg:text-left">
                        Cat No.: {product.catalogue_no}
                      </p>
                      <p className="border-black font-semibold md:border-l-2 md:pl-2">
                        Type: {product.type}
                      </p>
                      {isConfigLoaded &&
                        (!config.is_active ||
                          config?.config_list?.find(
                            (i: any) =>
                              i.config_key === "show_stock_quantity" &&
                              i.config_value,
                          )) && (
                          <p className="border-black font-semibold md:border-l-2 md:pl-2">
                            Quantity: {product.stock_quantity}
                          </p>
                        )}

                      {isConfigLoaded &&
                        config?.is_active &&
                        !config?.config_list?.find(
                          (i: any) =>
                            i.config_key === "show_stock_quantity" &&
                            i.config_value,
                        ) &&
                        config?.config_list?.find(
                          (i: any) =>
                            i.config_key === "show_stock_status" &&
                            i.config_value,
                        ) && (
                          <p className="flex items-center gap-2 border-black font-semibold md:border-l-2 md:pl-2">
                            Stock:
                            <span
                              className={`${
                                product.is_active
                                  ? "h-3 w-3 rounded-full border border-2 border-success/20 bg-success shadow-lg"
                                  : "h-3 w-3 rounded-full border border-2 border-error/20 bg-error shadow-lg"
                              }`}
                            ></span>
                          </p>
                        )}

                      <p className="text-center lg:text-left"></p>
                    </div>
                    <div>
                      <p className="font-semibold">{product.name}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    {isConfigLoaded &&
                    config?.config_list?.some(
                      (i: any) =>
                        i.config_key === "show_price" && i.config_value,
                    ) ? (
                      <div className="text-lg font-semibold">
                        {TEXT_CONSTANTS.CURRENCY}
                        {product.price}
                      </div>
                    ) : (
                      isConfigLoaded &&
                      !config.is_active && (
                        <div className="text-lg font-semibold">
                          {TEXT_CONSTANTS.CURRENCY}
                          {product.price}
                        </div>
                      )
                    )}

                    <div className="w-px self-stretch bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400"></div>
                    <div className="mt-4 flex w-full items-center justify-around rounded-md border bg-gray-200 p-2 lg:mt-0 lg:w-auto">
                      <button
                        className="p-2 text-gray-600 hover:text-gray-800"
                        type="button"
                        onClick={() =>
                          handleQuantityChange(
                            index,
                            Math.max(
                              0,
                              product.quantity - product.order_quantity,
                            ),
                            false,
                          )
                        }
                      >
                        <MinusIcon width={15} height={15} />
                      </button>
                      <input
                        type="number"
                        className="no-spinner-input w-1/2 border-0 bg-gray-200 text-center outline-none"
                        value={product.quantity || 0}
                        step={product.order_quantity}
                        min="0"
                        onChange={(e) =>
                          handelInputQuantityChange(
                            index,
                            0 || Number(e.target.value),
                          )
                        }
                      />
                      <button
                        className="p-2 text-gray-600 hover:text-gray-800"
                        type="button"
                        onClick={() =>
                          handleQuantityChange(
                            index,
                            (product.quantity || 0) + product.order_quantity,
                            true,
                          )
                        }
                      >
                        <PlusIcon width={15} height={15} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </form>
      )}
    </div>
  );
};

export default Product;
