import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Register from "./pages/auth/register/Register";
import SignIn from "./pages/auth/sign-in/SignIn";
import ProtectedRoute from "./shared/components/protected-route/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/auth/forgot-password/ForgotPassword";
import ApprovalStatus from "./pages/approval-status/ApprovalStatus";
import SearchedProducts from "./pages/searched-products/SearchedProducts";
import Product from "./pages/product/Product";
import Category from "./pages/category/Category";
import Address from "./pages/address/Address";
import Checkout from "./pages/checkout/Checkout";
import MyAccount from "./pages/my-account/MyAccount";
import Layout from "./shared/components/layout/Layout";
import "react-toastify/dist/ReactToastify.css";
import Cart from "./pages/cart/Cart";
import NotFound from "./pages/not-found/NotFound";
import apiService from "./services/apiService";
import { useDispatch } from "react-redux";
import { updateConfig } from "./redux/slices/configSlice";
import { updateCountries } from "./redux/slices/countriesSlice";
import Orders from "./pages/orders/Orders";
import OrderDetails from "./pages/orders/order-details/OrderDetails";
import ContactUs from "./pages/contact-us/ContactUs";
import ResetPassword from "./pages/reset-password/ResetPassword";
import EmailVerification from "./pages/email-verification/EmailVerification";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const App: React.FC = () => {
  const dispatch = useDispatch();
  async function initialFetch() {
    try {
      const { data: userConfig } = await apiService.getUserConfig();
      const countries = await apiService.getCountries();
      dispatch(updateConfig({ ...userConfig }));
      dispatch(updateCountries(countries));
      const faviconUrl = userConfig.config_list.find(
        (config: any) => config.config_key === "favicon_config",
      );
      if (faviconUrl.config_value.favicon) {
        updateFavicon(faviconUrl.config_value.favicon);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    initialFetch();
  }, []);

  const updateFavicon = (faviconUrl: string) => {
    const linkIcon = document.createElement("link");
    linkIcon.rel = "icon";
    linkIcon.href = faviconUrl;
    document.head.appendChild(linkIcon);

    const linkAppleIcon = document.createElement("link");
    linkAppleIcon.rel = "apple-touch-icon";
    linkAppleIcon.href = faviconUrl;
    document.head.appendChild(linkAppleIcon);

    return () => {
      document.head.removeChild(linkIcon);
      document.head.removeChild(linkAppleIcon);
    };
  };
  const commonRoutes = [
    { path: "/sign-in", element: <SignIn /> },
    { path: "/register", element: <Register /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "/approval-status", element: <ApprovalStatus /> },
    { path: "*", element: <NotFound /> },
    { path: "/email-verification", element: <EmailVerification /> },
  ];

  // protected routes
  const protectedRoutes = [
    { path: "address", element: <Address /> },
    { path: "my-account", element: <MyAccount /> },
    { path: "checkout", element: <Checkout /> },
    { path: "cart", element: <Cart /> },
    { path: "orders", element: <Orders /> },
    { path: "order-details/:orderId", element: <OrderDetails /> },
  ];

  // public route for pages with skeliton
  const publicRoutes = [
    { path: "search", element: <SearchedProducts /> },
    { path: "product", element: <Product /> },
    { path: "/", element: <Category /> },
    { path: "category", element: <Category /> },
    { path: "category/:categoryName", element: <Category />, index: true },
    { path: "category/:categoryName/:categoryId", element: <Category /> },
    { path: "product/:productName/:productId", element: <Product /> },
    { path: "/contact-us", element: <ContactUs /> },
  ];

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
    >
      <div className="font-sans">
        <Routes>
          {commonRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          {/* Common layout (Header sidebar and footer) */}
          <Route path="/" element={<Layout />}>
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
                index={route?.index}
              />
            ))}

            {/* Protected routes */}
            <Route path="/" element={<ProtectedRoute />}>
              {protectedRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Route>
          </Route>
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={3000} // 3 seconds
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default App;
