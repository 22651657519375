import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "../../shared/components/breadcrumb/Breadcrumb";
import { Stepper, NavigateToStepHandler } from "react-dynamic-stepper";
import OrderConfirmation from "./order-confirmation/OrderConfirmation";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { PlusIcon } from "@heroicons/react/24/solid";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import helperService from "../../services/helperService";
import AddressPopup from "../../../src/shared/components/add-edit-address/AddEditAddress";
import { useForm } from "react-hook-form";
import { ADDRESS_TYPE } from "../../../src/shared/constants/globalConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";
import CartReview from "./cart-review/CartReview";

const Checkout: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumb = [
    { name: t("common.HOME"), path: "/" },
    { name: t("common.CHECKOUT"), path: "" },
  ];
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const stepperRef = useRef<NavigateToStepHandler>(null);
  const [addresses, setAddresses] = useState([]);
  const [isBillingAddress, setIsBillingAddress] = useState(false);
  const [isShippingAddress, setIsShippingAddress] = useState(false);
  const [isOrderReviewed, setIsOrderReviewed] = useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [showStepper, setshowStepper] = useState(false);
  const [cartAddress, setCartAddress] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [orderNo, setOrderNo] = useState("");
  const [dateTime, setDateTime] = useState("");
  const { reset, setValue } = useForm({});
  const [selectedShippingAddressId, setSelectedShippingAddressId] = useState<
    number | null
  >(null); // State to track selected address
  const [selectedBillingAddressId, setSelectedBillingAddressId] = useState<
    number | null
  >(null); // State to track selected address

  const cartLength: any = useSelector(
    (state: RootState) => state.cart.cartLength,
  );
  // Dispatch updateCartLength to reset it to zero when the component is initialized
  useEffect(() => {
    if (cartLength < 1) {
      navigate("/");
    }
  }, []);

  //Get address management ui
  const getAddressManagement = (addressSelectionType: any) => {
    return (
      <>
        {/* Loader covering the entire section */}
        {loading ? (
          <div className="flex items-center justify-center pt-64">
            {" "}
            {/* Center loader vertically */}
            <ClipLoader color="#0000ff" loading={loading} size={50} />
          </div>
        ) : (
          <>
            <div className="w-full bg-white md:mx-auto">
              <div className="rounded-md bg-white">
                <div className="border-b pb-8 md:p-4">
                  <p className="font-bold text-offblack">
                    {addressSelectionType}
                  </p>
                </div>
                <div className="border-b pb-4 md:p-4">
                  <button
                    onClick={() => {
                      setCurrentAddress(null);
                      setShowModal(true);
                    }}
                    className="flex w-full items-center justify-center rounded-md border border-background-grey-300 bg-background-grey-100 p-8 text-xl font-bold text-offblack hover:bg-background-grey-200"
                  >
                    <PlusIcon className="mr-2 h-6 w-6" /> {t("common.ADD_NEW")}{" "}
                    {addressSelectionType}
                  </button>
                </div>
                <div className="space-y-4 sm:pb-4 md:p-4">
                  {/* Address 1 - Highlighted */}
                  {addresses.map((address: any) => (
                    <div
                      key={address.id}
                      onClick={() =>
                        updateCartAddress(address, addressSelectionType)
                      } // Handle address selection
                      className={`flex cursor-pointer items-center justify-between rounded-md border p-4 ${
                        (addressSelectionType === t("common.SHIPPING_ADDRESS")
                          ? selectedShippingAddressId
                          : selectedBillingAddressId) === address.address_id
                          ? "border-primary bg-blue-100" // Apply blue highlight to selected address
                          : "border-background-grey-300 bg-white"
                      }`}
                    >
                      <div className="p-2">
                        <p className="font-semibold">{address.name}</p>
                        <p
                          title={`${address.address_line1}${
                            address.address_line2
                              ? `, ${address.address_line2}`
                              : ""
                          }, ${address.city}, ${address.state}, ${
                            address.country
                          } - ${address.pincode}`}
                          className="max-w-[50rem] truncate text-wrap font-bold text-offblack sm:max-w-[20rem] md:max-w-[40rem]"
                        >
                          {`${address.address_line1}${
                            address.address_line2
                              ? `, ${address.address_line2}`
                              : ""
                          }, ${address.city}, ${address.state}, ${
                            address.country
                          } - ${address.pincode}`}
                        </p>
                      </div>
                      <button
                        id={address.address_id}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          setCurrentAddress(address);
                          setShowModal(true);
                          e.stopPropagation();
                          setValue("name", address.name);
                          setValue("address_line1", address.address_line1);
                          setValue("address_line2", address.address_line2);
                          setValue("city", address.city);
                          setValue("pincode", address.pincode);
                          setValue("state", address.state);
                          setValue("country", address.country);
                          setValue("default_billing", address.default_billing);
                          setValue(
                            "default_shipping",
                            address.default_shipping,
                          );
                        }}
                        className="rounded-sm bg-primary px-4 py-2 text-white hover:bg-primary-700"
                      >
                        {t("address_management.EDIT_ADDRESS")}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  //fetch user cart and address on component load
  useEffect(() => {
    // After the component has mounted, stepperRef.current is updated with the Stepper instance
    getCart();
    fetchAddresses();
  }, []);

  //set current state of stepper and save default shipping and billing address if any on initial load
  const setCurrentStep = (cartAddress: any) => {
    setCartAddress(cartAddress);
    setshowStepper(true);
  };

  //initial state of steer on compont load
  const setInitialStepperState = () => {
    if (!stepperRef.current) return; // Early return if stepperRef is not available

    setLoading(true);

    const { shipping_address_id, billing_address_id } = cartAddress;

    if (shipping_address_id && billing_address_id) {
      setIsBillingAddress(true);
      setIsShippingAddress(true);
      setIsOrderReviewed(true);
      setSelectedBillingAddressId(billing_address_id);
      setSelectedShippingAddressId(shipping_address_id);
      stepperRef.current.navigateToStep(2); // Navigate to step 2 when both addresses are present
    } else if (shipping_address_id) {
      setIsShippingAddress(true);
      setSelectedShippingAddressId(shipping_address_id);
      stepperRef.current.navigateToStep(0); // Navigate to billing address selection
      fetchAddresses(); // Fetch addresses for billing
    } else if (billing_address_id) {
      setIsBillingAddress(true);
      setSelectedBillingAddressId(billing_address_id);
      stepperRef.current.navigateToStep(1); // Navigate to shipping address selection
      fetchAddresses(); // Fetch addresses for shipping
    } else {
      stepperRef.current.navigateToStep(0); // Default to billing address step
      fetchAddresses(); // Fetch addresses if no address is selected
    }

    setLoading(false);
  };
  useEffect(() => {
    if (showStepper) {
      setInitialStepperState();
    }
  }, [showStepper]);

  //get all saved user address
  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const response: any = await apiService.getUserAddress();
      setAddresses(response.data.addresses);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  //get user cart
  const getCart = async () => {
    try {
      setLoading(true);
      const res: any = await apiService.getUserCart();
      setLoading(false);
      const cartAddress = {
        shipping_address_id: res.data.cart.shipping_address_id,
        billing_address_id: res.data.cart.billing_address_id,
      };
      initialLoad && setCurrentStep(cartAddress);
      setInitialLoad(false);
    } catch (error: any) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  //update cart billing/shipping address
  const updateCartAddress = async (address: any, addressType: string) => {
    try {
      setLoading(true);

      // Check if address needs to be updated
      const isShippingAddress = addressType === t("common.SHIPPING_ADDRESS");
      const isAlreadySelected = isShippingAddress
        ? selectedShippingAddressId === address.address_id
        : selectedBillingAddressId === address.address_id;

      if (isAlreadySelected) {
        // Navigate to appropriate step
        handleStepperNavigation();
        return;
      }

      // Prepare request object
      const addressTypeToUpdate = isShippingAddress ? "shipping" : "billing";
      const obj = {
        address_id: address.address_id,
        address_type: addressTypeToUpdate,
      };

      // Update address via API
      const res: any = await apiService.updateCartAddress(obj);

      // Update selected address state
      if (isShippingAddress) {
        setSelectedShippingAddressId(address.address_id);
        setIsShippingAddress(true);
        handleStepperNavigation(true);
      } else {
        setSelectedBillingAddressId(address.address_id);
        setIsBillingAddress(true);
        handleStepperNavigation(false);
      }

      // Display success message
      const successMessage = helperService.extractSuccessMessage(res);
      toast.success(successMessage);

      // Navigate to appropriate step
    } catch (error: any) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  //handle automatic stepper navigation on address selection
  const handleStepperNavigation = (isShippingAddress?: boolean) => {
    if (!stepperRef.current) return;

    if (!isShippingAddress) {
      // Billing address logic
      const step = selectedShippingAddressId ? 2 : 1;
      stepperRef.current.navigateToStep(step);
    } else {
      // Shipping address logic
      setIsOrderReviewed(true);
      stepperRef.current.navigateToStep(2);
    }
  };

  //place final order
  const placeOrder = async () => {
    setLoading(true);
    try {
      removeStepperButton();
      const res: any = await apiService.placeOrder();
      setOrderNo(res.data.order_no);

      if (res.data !== null) {
        setIsOrderReviewed(true);
        updateStepperOnOrder();
        setInitialLoad(false);
        const dateTime = helperService.formatDate(res.data.created_at);
        setDateTime(dateTime);
        const successMessage = helperService.extractSuccessMessage(res);
        toast.success(successMessage);
      }
    } catch (error: any) {
      navigate("/cart");
      const messages = error?.response?.data?.message;
      if (Array.isArray(messages)) {
        for (let err of messages) {
          toast.error(err.message);
        }
      } else {
        const errorMessage = helperService.extractErrorMessage(error);
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  //remove stepper button while order being placed
  const removeStepperButton = () => {
    const buttons = document.querySelectorAll(".stepper-button");
    if (buttons.length > 0) {
      const firstButton = buttons[0] as HTMLElement; // Target the first button
      const secondButton = buttons[1] as HTMLElement; // Target the first button
      firstButton.style.display = "none"; // Hide it
      secondButton.style.display = "none"; // Hide it
    }
  };

  //update stepper ui after succesfully placing order
  const updateStepperOnOrder = () => {
    setIsBillingAddress(false);
    setIsShippingAddress(false);
    setIsOrderReviewed(false);
    setIsOrderPlaced(true);
  };

  //set the type of address selection in progress on stepper
  const selectAddressMessage = (addressType: string) => {
    if (addressType === t("common.BILLING_ADDRESS") && !isBillingAddress) {
      toast.error("Please select a billing address."); // Or your desired error message
    }
    if (addressType === t("common.SHIPPING_ADDRESS") && !isShippingAddress) {
      toast.error("Please select a shipping address."); // Or your desired error message
    }
  };

  //steeper steps
  const steps = [
    {
      header: {
        label: t("common.BILLING_ADDRESS"),
      },
      content: <div>{getAddressManagement(t("common.BILLING_ADDRESS"))}</div>,
      isError: false,
      isWarning: false,
      isComplete: isBillingAddress,
      footer: {
        onClickHandler: () =>
          selectAddressMessage(ADDRESS_TYPE.BILLING_ADDRESS),
      },
    },
    {
      header: {
        label: t("common.SHIPPING_ADDRESS"),
      },
      content: <div>{getAddressManagement(t("common.SHIPPING_ADDRESS"))}</div>,

      isLoading: false,
      isError: false,
      isComplete: isShippingAddress,
      footer: {
        onClickHandler: () => setIsOrderReviewed(true),
      },
    },
    {
      header: {
        label: t("checkout.ORDER_REVIEW"),
      },
      content: (
        <>
          {loading ? (
            // Show loader while loading is true
            <div
              className="mt-10 flex h-full w-full items-center justify-center pt-10"
              data-testid="clip-loader"
            >
              <ClipLoader color="#0000ff" size={50} />
            </div>
          ) : (
            <CartReview />
          )}
        </>
      ),
      isError: false,
      isComplete: isOrderReviewed,
      isLoading: false,
      footer: {
        nextButtonLabel: t("checkout.COMPLETE_ORDER"),
        onClickHandler: () => placeOrder(),
      },
    },
    {
      header: {
        label: t("checkout.ORDER_COMPLETE"),
      },
      content: (
        <div>
          <OrderConfirmation
            message="John Doe"
            orderNo={orderNo}
            dateTime={dateTime}
          />
        </div>
      ),
      isError: false,
      isComplete: isOrderPlaced,
    },
  ];

  const submitStepper = () => {
    console.log("submitted");
  };

  return (
    <>
      <div className="max-h-[calc(100vh-90.3px)] overflow-y-auto">
        {/* Product Title and Add to Cart Button */}

        <div className="mb-4 flex flex-col items-center justify-between space-y-4 lg:flex-row lg:space-y-0">
          <h2 className="pl-1 text-center text-xl font-bold lg:text-left lg:text-2xl">
            {t("common.CHECKOUT")}
          </h2>
          <div className="w-64">
            <Link to="/">
              <button className="teal-button h-19 flex w-full items-center justify-center rounded-sm px-4 py-2 text-white lg:w-40">
                <span className="mr-2">
                  <PlusIcon className="mr-1 h-4 w-4" />
                </span>
                {t("common.ADD_ITEMS")}
              </button>
            </Link>
          </div>
        </div>
        {/* Breadcrumbs */}
        {/* Conditionally Render Breadcrumb */}
        <div className="ml-3 mt-2 pl-1 md:ml-0">
          <Breadcrumb breadcrumb={breadcrumb} />
        </div>
        <div className="p-2">
          <hr />
        </div>
        {/* Loader covering the entire section */}
        <div>
          {!showStepper ? (
            <div
              className="flex items-center justify-center pt-64"
              data-testid="clip-loader"
            >
              {/* Center loader vertically */}
              <ClipLoader color="#0000ff" size={50} />
            </div>
          ) : (
            <div>
              <Stepper
                ref={stepperRef}
                steps={steps}
                footerData={{
                  submitHandler: submitStepper,
                  prevBtnLabel: t("common.PREV_BUTTON"),
                  nextBtnClassName: "stepper-button",
                  prevBtnClassName: "stepper-button",
                  submitBtnLabel: t("checkout.CONFIRM_ORDER"),
                  submitBtnClassName: "submit-button",
                }}
                pallet={{
                  default: "#1079D4",
                  warning: "#D6D000",
                  danger: "#FE7A15",
                  success: "#1079D4",
                  disabled: "#bdbdbd",
                }}
              />
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <AddressPopup
          currentAddress={currentAddress}
          setShowModal={setShowModal}
          resetAddresses={reset}
          fetchAddresses={fetchAddresses}
          isLoading={loading}
        />
      )}
    </>
  );
};

export default Checkout;
