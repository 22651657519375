import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { myProfileSchema } from "../../shared/constants/validationSchemas";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import helperService from "../../services/helperService";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../shared/components/breadcrumb/Breadcrumb";
import ChangePasswordDialog from "../../shared/components/change-password/ChangePassword";

function MyAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [myAccountData, setMyAccountData] = useState<any>();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(myProfileSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      aadhar: "",
      companyName: "",
      gstNumber: "",
      drugLicence: "",
      phoneNumber: "",
    },
  });

  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const { data } = await apiService.getUserProfile();
      setMyAccountData(data);
      setValue("firstName", data.user.first_name || "");
      setValue("lastName", data.user.last_name || "");
      setValue("email", data.user.email || "");
      setValue("mobile", data.user.mobile_number || "");
      setValue("aadhar", data.user.aadhar_number || "");
      setValue("companyName", data.company.company_name || "");
      setValue("gstNumber", data.company.gst_number || "");
      setValue("drugLicence", data.company.drug_licence_number || "");
      setValue("phoneNumber", data.company.phone_number || "");
    } catch (error) {
      console.error("Error fetching profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [setValue]);

  const handleSubmitForm = async (data: any) => {
    const payload = {
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        mobile_number: data.mobile,
        aadhar_number: data?.aadhar || undefined,
      },
      company: {
        company_name: data.companyName,
        gst_number: data?.gstNumber || undefined,
        drug_licence_number: data?.drugLicence || undefined,
        phone_number: data?.phoneNumber || undefined,
      },
    };

    try {
      setIsLoading(true);
      const res = await apiService.updateUserProfile(payload);
      toast.success(helperService.extractSuccessMessage(res));
    } catch (error: any) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setIsLoading(false);
      reset();
      fetchProfile();
    }
  };

  const breadcrumb = [
    { name: t("bread_crums.HOME"), path: "/" },
    { name: t("my_account.MY_ACCOUNT"), path: "" },
  ];

  function changePassword() {
    setIsOpen(true);
  }
  return (
    <section className="max-h-[calc(100vh-90.3px)] overflow-y-auto">
      <div className="item-center mb-4 flex w-full flex-col border-b pb-4">
        <h1 className="text-2xl font-bold"> {t("my_account.MY_ACCOUNT")}</h1>
        <div className="mt-2">
          <Breadcrumb breadcrumb={breadcrumb} />
        </div>
      </div>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        {/* Personal Details */}
        <h2 className="text-xl font-bold">
          {t("my_account.MY_ACCOUNT_PERSONAL_DETAILS")}
        </h2>
        <p className="pt-1 text-sm">
          {t("my_account.MY_ACCOUNT_PERSONAL_DETAILS_LABEL")}
        </p>

        <section className="my-4">
          <div className="flex w-full flex-col gap-4 lg:flex-row">
            <div className="w-full lg:pb-4">
              <div className="flex">
                <label htmlFor="firstName">{t("user_info.FIRST_NAME")}</label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <input
                id="firstName"
                type="text"
                placeholder={t("place_holders.FIRST_NAME")}
                {...register("firstName")}
                className="w-full rounded-md border p-3"
                disabled={isLoading}
              />
              <p className="text-red-600">{errors.firstName?.message}</p>
            </div>

            <div className="w-full">
              <div className="flex">
                <label htmlFor="lastName">{t("user_info.LAST_NAME")}</label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <input
                id="lastName"
                type="text"
                placeholder={t("place_holders.LAST_NAME")}
                {...register("lastName")}
                className="w-full rounded-md border p-3"
                disabled={isLoading}
              />
              <p className="text-red-600">{errors.lastName?.message}</p>
            </div>

            <div className="w-full">
              <div className="flex">
                <label htmlFor="email">{t("user_info.EMAIL_ADDRESS")}</label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <input
                id="email"
                type="email"
                placeholder={t("place_holders.EMAIL")}
                {...register("email")}
                className="w-full rounded-md border p-3"
                disabled={isLoading}
              />
              <p className="text-red-600">{errors.email?.message}</p>
            </div>
          </div>

          <div className="flex w-full flex-col gap-4 pt-4 lg:flex-row lg:pt-0">
            <div className="w-full">
              <div className="flex">
                <label htmlFor="mobile">{t("user_info.MOBILE_NUMBER")}</label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <input
                id="mobile"
                type="text"
                placeholder={t("place_holders.MOBILE")}
                {...register("mobile")}
                className="w-full rounded-md border p-3"
                disabled={isLoading}
              />
              <p className="text-red-600">{errors.mobile?.message}</p>
            </div>

            <div className="w-full">
              <label htmlFor="aadhar">{t("user_info.AADHAR_NUMBER")}</label>
              <input
                id="aadhar"
                type="text"
                placeholder={t("place_holders.AADHAR")}
                {...register("aadhar")}
                className="w-full rounded-md border p-3"
                disabled={isLoading || myAccountData?.user?.aadhar_number}
              />
              <p className="text-red-600">{errors.aadhar?.message}</p>
            </div>
          </div>
        </section>

        <div className="w-full border-b"></div>
        {/* Business Details */}
        <h2 className="mt-2 text-xl font-bold">
          {t("my_account.MY_ACCOUNT_BUSINESS_DETAILS")}
        </h2>
        <p className="pt-1 text-sm">
          {t("my_account.MY_ACCOUNT_BUSINESS_DETAILS_LABEL")}
        </p>

        <section className="my-4 flex flex-col gap-4">
          <div className="flex w-full flex-col gap-4 lg:flex-row">
            <div className="w-full">
              <div className="flex">
                <label htmlFor="companyName">
                  {t("user_info.COMPANY_NAME")}
                </label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <input
                id="companyName"
                type="text"
                placeholder={t("place_holders.COMPANY_NAME")}
                {...register("companyName")}
                className="w-full rounded-md border p-3"
                disabled={isLoading}
              />
              <p className="text-red-600">{errors.companyName?.message}</p>
            </div>

            <div className="w-full">
              <label htmlFor="gstNumber">{t("user_info.GST_NUMBER")}</label>
              <input
                id="gstNumber"
                type="text"
                placeholder={t("place_holders.GST_NUMBER")}
                {...register("gstNumber")}
                className="w-full rounded-md border p-3"
                disabled={isLoading || myAccountData?.company?.gst_number}
              />
              <p className="text-red-600">{errors.gstNumber?.message}</p>
            </div>
          </div>

          <div className="flex w-full flex-col gap-4 lg:flex-row">
            <div className="w-full">
              <label htmlFor="drugLicence">
                {t("user_info.DRUG_LICENSE_NUMBER")}
              </label>
              <input
                id="drugLicence"
                type="text"
                placeholder={t("place_holders.DRUG_LICENSE_NUMBER")}
                {...register("drugLicence")}
                className="w-full rounded-md border p-3"
                disabled={
                  isLoading || myAccountData?.company?.drug_licence_number
                }
              />
              <p className="text-red-600">{errors.drugLicence?.message}</p>
            </div>

            <div className="w-full">
              <div className="flex">
                <label htmlFor="phoneNumber">
                  {t("user_info.COMPANY_PHONE_NUMbER")}
                </label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <input
                id="phoneNumber"
                type="text"
                placeholder={t("place_holders.MOBILE")}
                {...register("phoneNumber")}
                className="w-full rounded-md border p-3"
                disabled={isLoading}
              />
              <p className="text-red-600">{errors.phoneNumber?.message}</p>
            </div>
          </div>
        </section>

        <div className="w-full border-b"></div>

        <section className="item-center mt-2 flex justify-between max-md:flex-col max-md:gap-1">
          <div>
            <h2 className="text-xl font-bold">{t("common.CHANGE_PASSWORD")}</h2>
            <p className="pt-1 text-sm">{t("my_account.PASSWORD_CHANGE")}</p>
          </div>
          <button
            type="button"
            className="rounded-md border bg-gray-50 px-8 text-sm font-semibold max-md:py-2"
            onClick={changePassword}
          >
            {t("common.CHANGE_PASSWORD")}
          </button>
        </section>

        <div className="item-center mb-4 flex w-full justify-center">
          <button
            type="submit"
            className="teal-button mt-4 rounded-md px-20 py-4 text-white max-md:w-full"
            disabled={isLoading}
          >
            {t("common.SAVE_CHANGES")}
          </button>
        </div>
      </form>

      {isOpen ? (
        <ChangePasswordDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        ></ChangePasswordDialog>
      ) : (
        ""
      )}
    </section>
  );
}

export default MyAccount;
