import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import apiService from "../../services/apiService";
import helperService from "../../services/helperService";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "../../shared/constants/validationSchemas";
import {
  CheckIcon,
  EyeIcon,
  EyeSlashIcon,
  LanguageIcon,
} from "@heroicons/react/24/outline";
import HeroBanner from "../../shared/components/hero-banner/HeroBanner";
import RegisteredSuccess from "../../shared/components/registered-success/RegisteredSuccess";
import LanguageChange from "../../shared/components/language-change/LanguageChange";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [showPassword1, setShowPassword1] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);
  const [resetSuccess, setResetSuccess] = useState(false);
  // const [resetFailed, setResetFailed] = useState(false);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(resetPassword),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (!executeRecaptcha) {
        toast.error("ReCaptcha not loaded yet");
        return;
      }
      const token = await executeRecaptcha("resetpassword"); // You can pass an action name here
      const payload = {
        password: data.password,
        confirm_password: data.confirmPassword,
        recaptcha_token: token,
      };
      const response: any = await apiService.resetPassword(
        searchParams.get("token"),
        payload,
      );
      toast.success(helperService.extractSuccessMessage(response));
      setResetSuccess(true);
      reset();
    } catch (error) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (resetSuccess)
    return (
      <RegisteredSuccess
        subtitle="Successfully reset"
        description="Your password is reset successfully"
        button_label="Go to Login"
        button_link="/sign-in"
        icon={<CheckIcon className="h-10 w-10 text-success"></CheckIcon>}
      ></RegisteredSuccess>
    );

  // if (resetFailed)
  //   return (
  //     <RegisteredSuccess
  //       subtitle="Link is expired"
  //       description="The reset password link is expired"
  //       button_label="Send again"
  //       button_link="/forgot-password"
  //       icon={
  //         <ExclamationTriangleIcon className="h-10 w-10 text-error"></ExclamationTriangleIcon>
  //       }
  //       bg_color="bg-error/40"
  //     ></RegisteredSuccess>
  //   );

  return (
    <div className="flex min-h-screen flex-col lg:flex-row">
      <HeroBanner />
      <div className="flex w-full flex-col items-center justify-center p-12 lg:w-1/2">
        <div className="w-full max-w-md">
          <h2 className="mb-6 text-2xl font-bold">
            {t("user_info.RESET_PASSWORD")}
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
            <div className="mb-4">
              <div className="flex">
                <label className="mb-2 block">
                  {t("user_info.NEW_PASSWORD")}
                </label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <div className="relative">
                <input
                  {...register("password")}
                  type={showPassword1 ? "password" : "text"}
                  placeholder={t("user_info.NEW_PASSWORD")}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => setShowPassword1(!showPassword1)}
                  aria-label={showPassword1 ? "Show password" : "Hide password"}
                >
                  {showPassword1 ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                  )}
                </button>
              </div>

              <p className="text-red-500">{errors.password?.message}</p>
            </div>
            <div className="mb-4">
              <div className="flex">
                <label className="mb-2 block">
                  {t("user_info.CONFIRM_PASSWORD")}
                </label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <div className="relative">
                <input
                  {...register("confirmPassword")}
                  type={showPassword2 ? "password" : "text"}
                  placeholder={t("place_holders.CONFIRM_PASSWORD")}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => setShowPassword2(!showPassword2)}
                  aria-label={showPassword2 ? "Show password" : "Hide password"}
                >
                  {showPassword2 ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                  )}
                </button>
              </div>
              <p className="text-red-500">{errors.confirmPassword?.message}</p>
            </div>
            <button
              type="submit"
              className="w-full rounded bg-blue-500 p-3 font-semibold text-white hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? (
                <ClipLoader color="#ffffff" size={24} />
              ) : (
                t("user_info.RESET_PASSWORD")
              )}
            </button>
          </form>
          <div className="mt-4 flex flex-col items-center justify-center">
            <p className="text-sm font-bold">
              {t("common.BACK_TO")} {""}
              <a href="/sign-in" className="text-blue-500">
                {t("common.SIGN_IN")}
              </a>
            </p>
          </div>
        </div>

        <div className="absolute right-3 top-1">
          <div className="flex items-center justify-between gap-2">
            <LanguageIcon width={40} height={40}></LanguageIcon>
            <LanguageChange selectClass={"p-2 text-black"}></LanguageChange>
          </div>
        </div>
      </div>
    </div>
  );
}
