import { useTranslation } from "react-i18next";
import { languages } from "../../../assets/i18n";

function LanguageChange({ optionClass, selectClass }: any) {
  const { i18n } = useTranslation();

  const storedLanguage = localStorage.getItem("language");
  const currentLanguage = storedLanguage || "en";

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
    window.location.reload();
  };

  return (
    <select
      className={`${selectClass}`}
      onChange={handleLanguageChange}
      value={currentLanguage}
    >
      {languages.map((lang) => (
        <option key={lang} value={lang} className={optionClass}>
          {lang.toUpperCase()}
        </option>
      ))}
    </select>
  );
}

export default LanguageChange;
