import { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../shared/components/breadcrumb/Breadcrumb";
import { ArchiveBoxXMarkIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import QuickSearchAddItem from "../../shared/components/quick-search-add-item/QuickSearchAddItem";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import DialogueBox from "../../shared/components/dialogue-box/DialogueBox";
import debounce from "../../shared/utils/debounce";
import { useDispatch, useSelector } from "react-redux";
import { updateCartLength } from "../../redux/slices/cartSlice";
import { useTranslation } from "react-i18next";
import helperService from "../../services/helperService";
import NoDataPage from "../../shared/components/no-data-page/NoDataPage";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

function Cart() {
  const [cart, setCart] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>([]);
  const [totalQuantity, settotalQuantity] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showEmptyCartDialog, setShowEmptyCartDialog] = useState(false);
  const [showDeleteSelectedDialog, setShowDeleteSelectedDialog] =
    useState(false);
  const [quickAdd, setQuickAdd] = useState(false);
  const { config } = useSelector((state: any) => state.config);
  const isConfigLoaded = Object.keys(config).length !== 0;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchCart() {
      setIsLoading(true);
      try {
        await getUserCart();
        setSelectedItems([]);
      } catch (error) {
        toast.error(helperService.extractErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    }

    fetchCart();
  }, [quickAdd]);

  function handelSelectedItems(itemIds: any[], isChecked: boolean) {
    const updatedCart = cart.map((item: any) => {
      if (itemIds.includes(item.cart_item_id)) {
        return { ...item, checked: isChecked };
      }
      return item;
    });
    setCart(updatedCart);

    const selectedItems = updatedCart.filter(
      (item: any) => item.checked === true,
    );

    setSelectedItems(selectedItems);
  }

  async function getUserCart() {
    const res = await apiService.getUserCart();
    const transformedCart = res.data.cart?.cart_items?.map((i: any) => ({
      ...i,
      checked: false,
    }));
    settotalQuantity(res.data.cart.total_quantity);
    setTotalAmount(res.data.cart.total_amount);
    setCart(transformedCart);
    dispatch(updateCartLength({ length: transformedCart.length }));
  }

  async function deleteUserCart() {
    setIsLoading(true);
    try {
      const res = await apiService.deleteUserCart();
      await getUserCart();
      setShowEmptyCartDialog(false);
      toast.success(helperService.extractSuccessMessage(res));
    } catch (error: any) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteSelectedItemsCart() {
    setIsLoading(true);
    try {
      const payload = {
        delete_all: false,
        cart_item_ids: selectedItems.map((item: any) => item.cart_item_id),
      };
      const res = await apiService.deleteSelectedUserCartProduct(payload);
      await getUserCart();
      toast.success(helperService.extractSuccessMessage(res));
    } catch (error: any) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setShowDeleteSelectedDialog(false);
      setSelectedItems([]);
      setIsLoading(false);
    }
  }

  // const [breadcrumb] = useState<{ name: string; path: string }[]>([
  const breadcrumb = [
    { name: t("common.HOME"), path: "/" },
    { name: t("cart.MY_CART"), path: "" },
  ];

  const debouncedUpdateQuantity = useCallback(
    debounce(async (itemId: number, value: number) => {
      try {
        const res = await apiService.updateCartItemQuantity(itemId, {
          quantity: value,
        });

        settotalQuantity(res.data.cart.total_quantity);
        setTotalAmount(res.data.cart.total_amount);

        const selectedItem = res.data.cart.cart_items.find(
          (item: any) => item.cart_item_id === itemId,
        );

        setCart((prevCart: any) =>
          prevCart.map((item: any) => {
            if (item.cart_item_id === itemId) {
              return {
                ...item,
                quantity: selectedItem.quantity,
                sub_total: selectedItem.sub_total,
              };
            }
            return item;
          }),
        );

        toast.success(helperService.extractSuccessMessage(res));
      } catch (error) {
        toast.error(helperService.extractErrorMessage(error));
      }
    }, 500),
    [],
  );

  async function updateQuantity(
    index: number,
    value: number,
    callApi: boolean,
  ) {
    const itemToUpdate = cart[index];
    const updatedCart = [...cart];
    updatedCart[index] = { ...itemToUpdate, quantity: value };
    setCart(updatedCart);
    if (callApi) debouncedUpdateQuantity(itemToUpdate.cart_item_id, value);
  }

  return (
    <section className="max-h-[calc(100vh-90.3px)] overflow-y-auto">
      <div className="mb-4 flex items-center justify-between border-b pb-4">
        <div className="item-center flex flex-col">
          <h1 className="text-2xl font-bold"> {t("cart.MY_CART")}</h1>
          <div className="mt-2">
            <Breadcrumb breadcrumb={breadcrumb} />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <div
            className="inline-block size-10 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only"> {t("common.LOADING")}</span>
          </div>
        </div>
      ) : (
        <div>
          {cart?.length > 0 ? (
            <>
              <div className="flex items-center justify-end gap-2">
                <button
                  onClick={() => setShowEmptyCartDialog(true)}
                  disabled={isLoading}
                  className="flex items-center gap-1 rounded-s border px-4 py-2 hover:bg-gray-300"
                >
                  <ArchiveBoxXMarkIcon width={20} height={20} />
                  {t("cart.MY_CART_EMPTY_CONFIRM")}
                </button>
                <button
                  onClick={() => setShowDeleteSelectedDialog(true)}
                  disabled={selectedItems.length ? false : true}
                  className={`flex items-center gap-1 rounded-s border px-4 py-2 ${selectedItems.length ? "bg-error-200/50 hover:bg-error-200/70" : "cursor-not-allowed"}`}
                >
                  <TrashIcon width={20} height={20} />
                  {t("cart.MY_CART_DELETE_SELECTED")}
                </button>
              </div>

              <div className="mt-5 flex flex-col">
                <div className="max-h-[25rem] max-w-[calc(100vw-6rem)] flex-grow overflow-x-auto overflow-y-auto">
                  <table
                    data-testId="cart-table-render"
                    className="table-raw-group relative min-w-full border-collapse rounded-lg border border-gray-300"
                  >
                    <thead className="sticky left-0 top-[-1px] rounded-lg">
                      <tr className="text-nowrap bg-[#EDEDED] text-left">
                        <th className="flex items-center border-r border-background-grey-400 px-2 py-2">
                          <input
                            type="checkbox"
                            checked={
                              selectedItems.length === cart.length
                                ? true
                                : false
                            }
                            onClick={(v) =>
                              handelSelectedItems(
                                cart?.map((item: any) => item.cart_item_id),
                                v.currentTarget.checked,
                              )
                            }
                            className="form-checkbox h-6 w-6 text-blue-600"
                          />

                          <div className="pl-2">{t("cart.CHECKBOX")}</div>
                        </th>
                        <th className="border-r border-background-grey-400 px-2 py-2">
                          {t("cart.CATEGORY_NUMBER")}
                        </th>
                        <th className="border-r border-background-grey-400 px-2 py-2">
                          {t("cart.TYPE")}
                        </th>
                        <th className="border-r border-background-grey-400 px-2 py-2">
                          {t("cart.PRODUCT_NAME")}
                        </th>
                        <th className="border-r border-background-grey-400 px-2 py-2">
                          {t("cart.PENDING")}
                        </th>
                        {isConfigLoaded &&
                        config?.config_list?.some(
                          (i: any) =>
                            i.config_key === "show_price" && i.config_value,
                        ) ? (
                          <th className="border-r border-background-grey-400 px-2 py-2">
                            {t("common.CURRENCY_NAME")}
                          </th>
                        ) : (
                          isConfigLoaded &&
                          !config.is_active && (
                            <th className="border-r border-background-grey-400 px-2 py-2">
                              {t("common.CURRENCY_NAME")}
                            </th>
                          )
                        )}
                        <th className="border-r border-background-grey-400 px-2 py-2">
                          {t("cart.QUANTITY")}
                        </th>
                        {isConfigLoaded &&
                        config?.config_list?.some(
                          (i: any) =>
                            i.config_key === "show_price" && i.config_value,
                        ) ? (
                          <th className="border-r border-background-grey-400 px-2 py-2">
                            {t("cart.TOTAL")}
                          </th>
                        ) : (
                          isConfigLoaded &&
                          !config.is_active && (
                            <th className="border-r border-background-grey-400 px-2 py-2">
                              {t("cart.TOTAL")}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {cart?.map((item: any, index: number) => (
                        <tr
                          key={item.cart_item_id}
                          className={`${item.checked ? "bg-primary-100/50" : "bg-offwhite"}`}
                        >
                          <td className="border-b px-2 py-1">
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                data-testid="checkbox"
                                checked={item.checked}
                                onChange={(v) =>
                                  handelSelectedItems(
                                    [item.cart_item_id],
                                    v.target.checked,
                                  )
                                }
                                className="form-checkbox h-6 w-6 text-blue-600"
                              />

                              <span className="pl-2">{index + 1}</span>
                            </div>
                          </td>

                          <td className="border-b px-2 py-1">
                            {item.catalogue_no}
                          </td>
                          <td className="border-b px-2 py-1">
                            {item.product_type}
                          </td>
                          <td className="border-b px-2 py-1">
                            {item.product_name}
                          </td>
                          <td className="border-b px-2 py-1">
                            {item.pending_quantity}
                          </td>
                          {isConfigLoaded &&
                          config?.config_list?.some(
                            (i: any) =>
                              i.config_key === "show_price" && i.config_value,
                          ) ? (
                            <td className="border-b px-2 py-1">
                              {t("common.CURRENCY_SYMBOL")}
                              {item.price}
                            </td>
                          ) : (
                            isConfigLoaded &&
                            !config.is_active && (
                              <td className="border-b px-2 py-1">
                                {t("common.CURRENCY_SYMBOL")}
                                {item.price}
                              </td>
                            )
                          )}

                          <td className="border-b px-2 py-1">
                            <div className="item-center flex gap-1">
                              <div className="mx-auto flex items-center rounded-md bg-white px-1">
                                <button
                                  onClick={() => {
                                    if (
                                      item.quantity >
                                      item.product.order_quantity
                                    ) {
                                      updateQuantity(
                                        index,
                                        item.quantity -
                                          item.product.order_quantity,
                                        true,
                                      );
                                    }
                                  }}
                                >
                                  <MinusIcon width={15} height={15} />
                                </button>
                                <input
                                  type="number"
                                  className="no-spinner-input w-20 border-0 bg-white text-center outline-none"
                                  value={item.quantity}
                                  min={item.product.order_quantity}
                                  step={item.product.order_quantity}
                                  onChange={(e) => {
                                    const input = e.target;
                                    input.setCustomValidity("");
                                    const isValid = input.reportValidity();
                                    if (isValid) {
                                      updateQuantity(
                                        index,
                                        Number(input.value),
                                        true,
                                      );
                                    } else {
                                      updateQuantity(
                                        index,
                                        Number(input.value),
                                        false,
                                      );
                                      input.setCustomValidity(
                                        `Please enter a valid quantity in multiple of ${item.product.order_quantity}`,
                                      );
                                    }
                                    input.title = input.validationMessage;
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    updateQuantity(
                                      index,
                                      item.quantity +
                                        item.product.order_quantity,
                                      true,
                                    );
                                  }}
                                >
                                  <PlusIcon width={15} height={15} />
                                </button>
                              </div>
                            </div>
                          </td>

                          {isConfigLoaded &&
                          config?.config_list?.some(
                            (i: any) =>
                              i.config_key === "show_price" && i.config_value,
                          ) ? (
                            <td className="border-b px-2 py-1">
                              &#8377;{item.sub_total}
                            </td>
                          ) : (
                            isConfigLoaded &&
                            !config.is_active && (
                              <td className="border-b px-2 py-1">
                                &#8377;{item.sub_total}
                              </td>
                            )
                          )}
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="relative sticky bottom-[-1px] bg-primary-100">
                      <tr className="bg-primary/20">
                        <td className="absolute border-primary-400 px-2 py-2 font-semibold">
                          {isConfigLoaded &&
                            (config.is_active === false ||
                            config?.config_list?.some(
                              (i: any) =>
                                i.config_key === "show_price" && i.config_value,
                            )
                              ? t("cart.QUANTITY_AND_ORDER_TOTAL")
                              : t("cart.QUANTITY"))}
                        </td>
                        <td className="border-primary-400 px-2 py-2"></td>
                        <td className="border-primary-400 px-2 py-2"></td>
                        <td className="border-primary-400 px-2 py-2"></td>
                        <td className="border-primary-400 px-2 py-2"></td>

                        {isConfigLoaded &&
                          (config.is_active === false ||
                          config?.config_list?.some(
                            (i: any) =>
                              i.config_key === "show_price" && i.config_value,
                          ) ? (
                            <td className="border-primary-400 px-2 py-2"></td>
                          ) : (
                            ""
                          ))}

                        <td className="tableborder-primary-400 py-2 text-center font-semibold">
                          {totalQuantity}
                        </td>

                        {isConfigLoaded &&
                        config?.config_list?.some(
                          (i: any) =>
                            i.config_key === "show_price" && i.config_value,
                        ) ? (
                          <td className="border-primary-400 px-2 py-2 font-semibold">
                            {t("common.CURRENCY_SYMBOL")}
                            {totalAmount}
                          </td>
                        ) : (
                          isConfigLoaded &&
                          !config.is_active && (
                            <td className="border-primary-400 px-2 py-2 font-semibold">
                              {t("common.CURRENCY_SYMBOL")}
                              {totalAmount}
                            </td>
                          )
                        )}
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <div className="pt-10">
                  <QuickSearchAddItem setQuickAdd={setQuickAdd} />
                </div>

                <div className="my-10 flex items-center justify-center gap-10 px-6 py-3 max-sm:flex-col max-md:gap-5">
                  <Link
                    className="rounded-sm border bg-primary px-10 py-3 text-white max-md:px-5"
                    to={"/category/root"}
                  >
                    {t("cart.EXPLORE_MORE_ITEMS")}
                  </Link>

                  <Link
                    className="rounded-sm border bg-success px-14 py-3 text-white max-md:px-7"
                    to={"/checkout"}
                  >
                    {t("cart.CHECKOUT_PAGE")}
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <NoDataPage
              title={t("cart.MY_CART_EMPTY")}
              message={t("cart.MY_CART_EMPTY_MESSAGE")}
              buttonText={t("cart.MY_CART_GO_TO_PRODUCT_PAGE")}
              link="/category/root"
            />
          )}
        </div>
      )}

      {showEmptyCartDialog && (
        <DialogueBox
          icon={
            <ArchiveBoxXMarkIcon
              width={40}
              height={40}
              className="text-warning-300"
            />
          }
          heading={t("cart.MY_CART_EMPTY_DIALOG")}
          message={t("cart.MY_CART_EMPTY_DIALOG_MESSAGE")}
          cancel={t("common.CANCEL")}
          confirm={t("cart.MY_CART_EMPTY_CONFIRM")}
          handleConfirm={deleteUserCart}
          setShowDialog={setShowEmptyCartDialog}
          showDialog={showEmptyCartDialog}
          isLoading={isLoading}
          iconGradient="bg-warning/10"
        ></DialogueBox>
      )}
      {showDeleteSelectedDialog && (
        <DialogueBox
          icon={<TrashIcon width={40} height={40} className="text-error" />}
          heading={t("cart.MY_CART_DELETE_SELECTED_DIALOG")}
          message={t("cart.MY_CART_DELETE_SELECTED_DIALOG_MESSAGE")}
          cancel={t("common.CANCEL")}
          confirm={t("cart.MY_CART_DELETE_SELECTED")}
          handleConfirm={deleteSelectedItemsCart}
          setShowDialog={setShowDeleteSelectedDialog}
          showDialog={showDeleteSelectedDialog}
          isLoading={isLoading}
          iconGradient="bg-error/10"
        ></DialogueBox>
      )}
    </section>
  );
}

export default Cart;
