import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import apiService from "../../../services/apiService";
import { emailValidationSchema } from "../../../shared/constants/validationSchemas";
import { useTranslation } from "react-i18next";
import helperService from "../../../services/helperService";
import HeroBanner from "../../../shared/components/hero-banner/HeroBanner";
import { LanguageIcon } from "@heroicons/react/24/outline";
import LanguageChange from "../../../shared/components/language-change/LanguageChange";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(emailValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response: any = await apiService.forgotPassword(data);
      if (response.status) {
        toast.success(helperService.extractSuccessMessage(response));
        navigate("/sign-in");
      }
    } catch (error) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen flex-col lg:flex-row">
      <HeroBanner></HeroBanner>
      <div className="flex w-full flex-col items-center justify-center p-12 lg:w-1/2">
        <div className="w-full max-w-md">
          <h2 className="mb-6 text-2xl font-bold">
            {t("common.FORGOT_PASSWORD")}
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
            <div className="mb-4">
              <div className="flex">
                <label className="mb-2 block">{t("user_info.EMAIL")}</label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <input
                {...register("email")}
                type="email"
                className="w-full rounded-md border p-3 focus:outline-none focus:ring focus:ring-blue-500"
                onBlur={() => trigger("email")}
                placeholder={t("place_holders.EMAIL")}
              />
              <p className="text-red-500">{errors.email?.message}</p>
            </div>
            <button
              type="submit"
              className="w-full rounded bg-blue-500 p-3 font-semibold text-white hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? (
                <ClipLoader color="#ffffff" size={24} />
              ) : (
                t("common.SEND_RESET_LINK")
              )}
            </button>
          </form>
          <div className="mt-4 flex flex-col items-center justify-center">
            <p className="text-sm font-bold">
              {t("sign_in.ALREADY_HAVE_ACCOUNT")}{" "}
              <a href="/sign-in" className="text-blue-500">
                {t("common.SIGN_IN")}
              </a>
            </p>
          </div>
        </div>

        <div className="absolute right-3 top-1">
          <div className="flex items-center justify-between gap-2">
            <LanguageIcon width={40} height={40}></LanguageIcon>
            <LanguageChange selectClass={"p-2 text-black"}></LanguageChange>
          </div>
        </div>
      </div>
    </div>
  );
}
