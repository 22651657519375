import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactValidation } from "../../constants/validationSchemas";
import apiService from "../../../services/apiService";
import { toast } from "react-toastify";
import helperService from "../../../services/helperService";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ContactUsForm = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(contactValidation),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      companyName: "",
      phoneNumber: "",
      subject: "",
      message: "",
    },
  });

  const submit = async (data: any) => {
    setIsLoading(true);
    try {
      const { name, email, message, subject, phoneNumber, companyName } = data;
      let payload: any = {
        user_name: name,
        email,
        message,
        subject,
        mobile_number: phoneNumber || undefined,
        company_name: companyName || undefined,
      };
      if (!executeRecaptcha) {
        toast.error("ReCaptcha not loaded yet");
        return;
      }
      const token = await executeRecaptcha("contactus"); // You can pass an action name here

      // Now you can send the token along with the form data to your backend 
      if(!localStorage.getItem("accessToken")){
       payload = {...payload, recaptcha_token: token}
      }
      const res = await apiService.createTicket(payload);
      toast.success(helperService.extractSuccessMessage(res));
    } catch (error) {
      toast.error(helperService.extractSuccessMessage(error));
    } finally {
      reset();
      setIsLoading(false);
    }
  };

  return (
    <form data-testid="contact-form" onSubmit={handleSubmit(submit)}>
      <div className="mb-4 flex flex-col gap-3 md:flex-row">
        <div className="w-full">
          <div className="flex">
            <label> {t("user_info.NAME")} </label>
            <span className="text-error">
              {t("common.REQUIRED_FIELD_INDICATOR")}
            </span>
          </div>
          <input
            {...register("name")}
            data-testid="input-name"
            type="text"
            onBlur={() => trigger("name")}
            placeholder={t("place_holders.NAME")}
          />
          <p data-testid="error-name" className="text-red-500">
            {errors.name?.message}
          </p>
        </div>

        <div className="w-full">
          <div className="flex">
            <label> {t("user_info.EMAIL")} </label>
            <span className="text-error">
              {t("common.REQUIRED_FIELD_INDICATOR")}
            </span>
          </div>
          <input
            {...register("email")}
            data-testid="input-email"
            type="email"
            onBlur={() => trigger("email")}
            placeholder={t("place_holders.EMAIL")}
          />
          <p data-testid="error-email" className="text-red-500">
            {errors.email?.message}
          </p>
        </div>
      </div>

      <div className="mb-4 flex flex-grow flex-col gap-3 md:flex-row">
        <div className="w-full">
          <label> {t("user_info.COMPANY_NAME")} </label>
          <input
            {...register("companyName")}
            data-testid="input-companyName"
            type="text"
            onBlur={() => trigger("companyName")}
            placeholder={t("place_holders.COMPANY_NAME")}
          />
          <p data-testid="error-companyName" className="text-red-500">
            {errors.companyName?.message}
          </p>
        </div>

        <div className="w-full">
          <label> {t("user_info.PHONE_NUMBER")} </label>
          <input
            {...register("phoneNumber")}
            data-testid="input-phoneNumber"
            type="text"
            onBlur={() => trigger("phoneNumber")}
            placeholder={t("place_holders.PHONE_NUMBER")}
          />
          <p data-testid="error-phoneNumber" className="text-red-500">
            {errors.phoneNumber?.message}
          </p>
        </div>
      </div>

      <div className="mb-4">
        <div className="flex">
          <label> {t("user_info.SUBJECT")} </label>
          <span className="text-error">
            {t("common.REQUIRED_FIELD_INDICATOR")}
          </span>{" "}
        </div>
        <input
          {...register("subject")}
          data-testid="input-subject"
          type="text"
          onBlur={() => trigger("subject")}
          placeholder={t("place_holders.SUBJECT")}
        />
        <p data-testid="error-subject" className="text-red-500">
          {errors.subject?.message}
        </p>
      </div>

      <div className="mb-4 flex flex-col">
        <div className="flex">
          <label> {t("user_info.MESSAGE")} </label>
          <span className="text-error">
            {t("common.REQUIRED_FIELD_INDICATOR")}
          </span>
        </div>
        <textarea
          {...register("message")}
          data-testid="input-message"
          onBlur={() => trigger("message")}
          placeholder={t("place_holders.MESSAGE")}
          className="min-h-32 rounded-md border p-3"
        />
        <p data-testid="error-message" className="text-red-500">
          {errors.message?.message}
        </p>
      </div>
      <button
        data-testid="submit-button"
        disabled={isLoading}
        className="mt-2 w-full rounded-md bg-primary py-2 text-white"
      >
        {t("common.SEND_MESSAGE")}
      </button>
    </form>
  );
};

export default ContactUsForm;
