import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TEXT_CONSTANTS } from "../../../shared/constants/globalConstants";
import {
  CheckIcon,
  EyeIcon,
  EyeSlashIcon,
  LanguageIcon,
} from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerValidationSchema } from "../../../shared/constants/validationSchemas";
import apiService from "../../../services/apiService";
import helperService from "../../../services/helperService";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import RegisteredSuccess from "../../../shared/components/registered-success/RegisteredSuccess";
import { useTranslation } from "react-i18next";
import LanguageChange from "../../../shared/components/language-change/LanguageChange";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function Register() {
  //# region variables
  const { t } = useTranslation();
  const [steps, setSteps] = useState([
    { label: t("registration.PERSONAL_DETAILS"), status: "in-progress" },
    { label: t("registration.COMPANY_DETAILS"), status: "not-completed" },
    {
      label: t("registration.LICENSE_BUSINESS_DETAILS"),
      status: "not-completed",
    },
  ]);

  useEffect(() => {
    setSteps((prev) => {
      return [
        { label: t("registration.PERSONAL_DETAILS"), status: prev[0].status },
        { label: t("registration.COMPANY_DETAILS"), status: prev[1].status },
        {
          label: t("registration.LICENSE_BUSINESS_DETAILS"),
          status: prev[2].status,
        },
      ];
    });
  }, [t]);

  // Track the current step index
  const [currentStep, setCurrentStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [registeredSuccess, setRegisteredSuccess] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user: {},
    company: {},
  }); // Initialize formData
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    formState: { errors },
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(registerValidationSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  //methoud that handles signup api call and signs up user
  const handleSignUp = async (data: any) => {
    setLoading(true); // Start loader
    data = helperService.removeEmptyKeys(data);
    try {
      if (!executeRecaptcha) {
        toast.error("ReCaptcha not loaded yet");
        return;
      }
  
      const token = await executeRecaptcha("register"); // You can pass an action name here
      const response: any = await apiService.signup({...data,recaptcha_token: token});
      const successMessage = helperService.extractSuccessMessage(response);
      toast.success(successMessage);
      setRegisteredSuccess(true);
    } catch (error: any) {
      console.error("Signup failed:", error);
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Stop loader
    }
  };
  //# region functions
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data: any[] = await apiService.getCountries();
        setCountries(data); // TypeScript should recognize the type now
      } catch (error) {
        const errorMessage = helperService.extractErrorMessage(error);
        toast.error(errorMessage);
      }
    };
    fetchCountries();
  }, []);

  //Registraton form is 3 step form and this function handles every step
  const handleNextStep = async (e: any) => {
    e.preventDefault(); // Prevent the default form submission behavior
    let isStepValid = false;

    // Switch based on the current step
    switch (currentStep) {
      case 0:
        isStepValid = await validateStep0();
        break;
      case 1:
        isStepValid = await validateStep1();
        break;
      case 2:
        isStepValid = await validateStep2();
        break;
      default:
        break;
    }

    if (isStepValid) {
      updateStepper("next");
    }
  };

  const handlePrevStep = async (e: any) => {
    e.preventDefault();
    updateStepper("prev");
  };

  //validate steps functions are steps to validate a specific step of form before letting user move to next step
  const validateStep0 = async () => {
    const isValid = await trigger([
      "first_name",
      "last_name",
      "email",
      "mobile_number",
      "aadhar_number",
    ]);
    if (isValid) {
      updateFormDataStep0();
    }
    return isValid;
  };

  //update form data are functions that maintains the value collected form each step of form and store the all collected value in one single variable
  const updateFormDataStep0 = () => {
    setFormData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        first_name: watch("first_name"),
        last_name: watch("last_name"),
        email: watch("email"),
        mobile_number: watch("mobile_number"),
        aadhar_number: watch("aadhar_number"),
        user_type: "dealer", // Add static value as per your structure
      },
    }));
  };

  //validate steps functions are steps to validate a specific step of form before letting user move to next step
  const validateStep1 = async () => {
    const isValid = await trigger([
      "company_name",
      "address",
      "city",
      "pincode",
      "state",
      "country",
    ]);
    if (isValid) {
      updateFormDataStep1();
    }
    return isValid;
  };

  //update form data are functions that maintains the value collected form each step of form and store the all collected value in one single variable
  const updateFormDataStep1 = () => {
    setFormData((prev) => ({
      ...prev,
      company: {
        ...prev.company,
        company_name: watch("company_name"),
        address: watch("address"),
        city: watch("city"),
        pincode: watch("pincode"),
        state: watch("state"),
        country: watch("country"),
      },
    }));
  };

  //validate steps functions are steps to validate a specific step of form before letting user move to next step
  const validateStep2 = async () => {
    const isValid = await trigger([
      "gst_number",
      "drug_licence_number",
      "phone_number",
      "business_type",
      "password",
      "confirm_password",
      "termsAccepted",
    ]);
    if (isValid) {
      updateFormDataStep2();
    }
    return isValid;
  };

  //update form data are functions that maintains the value collected form each step of form and store the all collected value in one single variable
  const updateFormDataStep2 = () => {
    const newFormData = {
      ...formData,
      company: {
        ...formData.company,
        gst_number: watch("gst_number"),
        drug_licence_number: watch("drug_licence_number"),
        phone_number: watch("phone_number"),
        business_type: watch("business_type"),
      },
      user: {
        ...formData.user,
        password: watch("password"),
      },
    };

    // Update state
    setFormData(newFormData);
    handleSignUp(newFormData);
  };

  const updateStepper = (action: string) => {
    if (action === "next") {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        if (currentStep < updatedSteps.length - 1) {
          updatedSteps[currentStep].status = "completed"; // Complete the current step
          updatedSteps[currentStep + 1].status = "in-progress"; // Set next step to in-progress
          setCurrentStep(currentStep + 1); // Move to the next step
        }
        return updatedSteps;
      });
    } else if (action === "prev") {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        if (currentStep > 0) {
          updatedSteps[currentStep].status = "not-completed"; // change the current step value to not complete
          updatedSteps[currentStep - 1].status = "in-progress"; // Set prev step to in-progress
          setCurrentStep(currentStep - 1); // Move to the prev step
        }
        return updatedSteps;
      });
    } else {
      return;
    }
  };

  const renderCurrentForm = () => {
    return (
      <div className="w-full">
        <div className="m-8 max-md:m-0">
          <div className="w-full max-w-md">
            <p className="align-left text-md mb-2 mt-10 font-bold text-blue-500">
              {t("common.REGISTER")}
            </p>
            <h3 className="align-left mb-10 text-2xl font-semibold">
              {currentStep === 0
                ? t("registration.PERSONAL_DETAILS")
                : currentStep === 1
                  ? t("registration.COMPANY_DETAILS")
                  : t("registration.LICENSE_BUSINESS_DETAILS")}
            </h3>
          </div>
          <div className="w-full">
            <form className="w-full rounded-md bg-white">
              {currentStep === 0 && (
                <>
                  <div className="mb-4 flex flex-col space-y-4">
                    <div className="flex flex-col sm:flex-row sm:space-x-4">
                      <div className="w-full sm:mb-0">
                        <div className="flex">
                          <label className="text-l font-small mb-2 block">
                            {t("user_info.FIRST_NAME")}
                          </label>
                          <span className="text-error">
                            {t("common.REQUIRED_FIELD_INDICATOR")}
                          </span>
                        </div>
                        <input
                          {...register("first_name")}
                          type="text"
                          placeholder={t("place_holders.FIRST_NAME")}
                          onBlur={() => trigger("first_name")}
                        />
                        <p className="text-red-500">
                          {errors?.first_name?.message}
                        </p>
                      </div>
                      <div className="w-full">
                        <div className="flex">
                          <label className="text-l font-small mb-2 block">
                            {t("user_info.LAST_NAME")}
                          </label>
                          <span className="text-error">
                            {t("common.REQUIRED_FIELD_INDICATOR")}
                          </span>
                        </div>
                        <input
                          {...register("last_name")}
                          type="text"
                          placeholder={t("place_holders.LAST_NAME")}
                          onBlur={() => trigger("last_name")}
                        />
                        <p className="text-red-500">
                          {errors?.last_name?.message}
                        </p>
                      </div>
                    </div>

                    <div className="mb-4 w-full">
                      <div className="flex">
                        <label className="text-l font-small mb-2 block">
                          {t("user_info.EMAIL_ADDRESS")}
                        </label>
                        <span className="text-error">
                          {t("common.REQUIRED_FIELD_INDICATOR")}
                        </span>
                      </div>
                      <input
                        {...register("email")}
                        type="email"
                        placeholder={t("place_holders.EMAIL")}
                        onBlur={() => trigger("email")}
                      />
                      <p className="text-red-500">{errors?.email?.message}</p>
                    </div>
                    <div className="mb-4 w-full">
                      <label className="text-l font-small mb-2 block">
                        {t("user_info.MOBILE_NUMBER")}
                      </label>
                      <input
                        {...register("mobile_number")}
                        type="text"
                        placeholder={t("place_holders.PHONE_NUMBER")}
                        maxLength={10}
                      />
                      <p className="text-red-500">
                        {errors?.mobile_number?.message}
                      </p>
                    </div>
                    <div className="mb-4 w-full">
                      <label className="text-l font-small mb-2 block">
                        {t("user_info.AADHAR_NUMBER")}
                      </label>
                      <input
                        {...register("aadhar_number")}
                        type="text"
                        placeholder={t("place_holders.AADHAR")}
                        maxLength={12}
                      />
                      <p className="text-red-500">
                        {errors?.aadhar_number?.message}
                      </p>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <button
                      className="button-color w-full rounded p-3 font-semibold text-white"
                      onClick={handleNextStep}
                    >
                      {t("common.NEXT_BUTTON")}
                    </button>
                  </div>
                </>
              )}
              {currentStep === 1 && (
                <>
                  <div className="mb-4 flex flex-col space-y-4">
                    <div className="">
                      <div className="flex">
                        <label className="font-small mb-2 block text-gray-700">
                          {t("user_info.COMPANY_NAME")}
                        </label>
                        <span className="text-error">
                          {t("common.REQUIRED_FIELD_INDICATOR")}
                        </span>
                      </div>
                      <input
                        {...register("company_name")}
                        type="text"
                        name="company_name"
                        placeholder={t("place_holders.COMPANY_NAME")}
                        onBlur={() => trigger("company_name")}
                      />
                      <p className="text-red-500">
                        {errors?.company_name?.message}
                      </p>
                    </div>

                    <div className="mb-4">
                      <div className="flex">
                        <label className="font-small mb-2 block text-gray-700">
                          {t("user_info.ADDRESS")}
                        </label>
                        <span className="text-error">
                          {t("common.REQUIRED_FIELD_INDICATOR")}
                        </span>
                      </div>
                      <input
                        {...register("address")}
                        type="text"
                        placeholder={t("place_holders.ADDRESS")}
                        onBlur={() => trigger("address")}
                      />
                      <p className="text-red-500">{errors?.address?.message}</p>
                    </div>

                    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <div className="w-full">
                        <div className="flex">
                          <label className="font-small mb-2 block text-gray-700">
                            {t("user_info.CITY")}
                          </label>
                          <span className="text-error">
                            {t("common.REQUIRED_FIELD_INDICATOR")}
                          </span>
                        </div>
                        <input
                          {...register("city")}
                          type="text"
                          name="city"
                          placeholder={t("place_holders.CITY")}
                          onBlur={() => trigger("city")}
                        />
                        <p className="text-red-500">{errors?.city?.message}</p>
                      </div>
                      <div className="w-full">
                        <div className="flex">
                          <label className="font-small mb-2 block text-gray-700">
                            {t("user_info.PINCODE")}
                          </label>
                          <span className="text-error">
                            {t("common.REQUIRED_FIELD_INDICATOR")}
                          </span>
                        </div>
                        <input
                          {...register("pincode")}
                          type="text"
                          placeholder={t("place_holders.PINCODE")}
                          onBlur={() => trigger("pincode")}
                        />
                        <p className="text-red-500">
                          {errors?.pincode?.message}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <div className="w-full">
                        <div className="flex">
                          <label className="font-small mb-2 block text-gray-700">
                            {t("user_info.STATE")}
                          </label>
                          <span className="text-error">
                            {t("common.REQUIRED_FIELD_INDICATOR")}
                          </span>
                        </div>
                        <input
                          {...register("state")}
                          type="text"
                          name="state"
                          placeholder={t("place_holders.STATE")}
                          onBlur={() => trigger("state")}
                        />
                        <p className="text-red-500">{errors?.state?.message}</p>
                      </div>
                      <div className="w-full">
                        <div className="flex">
                          <label className="font-small mb-2 block text-gray-700">
                            {t("user_info.COUNTRY")}
                          </label>
                          <span className="text-error">
                            {t("common.REQUIRED_FIELD_INDICATOR")}
                          </span>
                        </div>
                        <select
                          {...register("country")}
                          onBlur={() => trigger("country")}
                        >
                          <option value="INDIA">
                            {t("place_holders.COUNTRY")}
                          </option>
                          {countries?.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <button
                        className="w-full rounded bg-background-grey-300 p-3 font-semibold text-white"
                        onClick={handlePrevStep}
                      >
                        {t("common.PREV_BUTTON")}
                      </button>
                      <button
                        className="button-color w-full rounded p-3 font-semibold text-white"
                        onClick={handleNextStep}
                      >
                        {t("common.NEXT_BUTTON")}
                      </button>
                    </div>
                  </div>
                </>
              )}
              {currentStep === 2 && (
                <>
                  <div className="mb-4 flex flex-col space-y-4">
                    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <div className="w-full">
                        <label className="font-small mb-2 block text-gray-700">
                          {t("user_info.GST_NUMBER")}
                        </label>
                        <input
                          {...register("gst_number")}
                          type="text"
                          placeholder={t("place_holders.GST_NUMBER")}
                        />
                      </div>

                      <div className="w-full">
                        <label className="font-small mb-2 block text-gray-700">
                          {t("user_info.DRUG_LICENSE_NUMBER")}
                        </label>
                        <input
                          {...register("drug_licence_number")}
                          type="text"
                          placeholder={t("place_holders.DRUG_LICENSE_NUMBER")}
                          onBlur={() => trigger("drug_licence_number")}
                        />
                        <p className="text-red-500">
                          {errors?.drug_licence_number?.message}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <div className="w-full">
                        <div className="flex">
                          <label className="font-small mb-2 block text-gray-700">
                            {t("user_info.PHONE_NUMBER")}
                          </label>
                          <span className="text-error">
                            {t("common.REQUIRED_FIELD_INDICATOR")}
                          </span>
                        </div>
                        <input
                          {...register("phone_number")}
                          type="text"
                          maxLength={10}
                          placeholder={t("place_holders.PHONE_NUMBER")}
                          onBlur={() => trigger("phone_number")}
                        />
                        <p className="text-red-500">
                          {errors?.phone_number?.message}
                        </p>
                      </div>

                      <div className="w-full">
                        <div className="flex">
                          <label className="font-small mb-2 block text-gray-700">
                            {t("user_info.BUSINESS_TYPE")}
                          </label>
                          <span className="text-error">
                            {t("common.REQUIRED_FIELD_INDICATOR")}
                          </span>
                        </div>
                        <select {...register("business_type")}>
                          <option value="domestic">Domestic</option>
                          <option value="international">International</option>
                        </select>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="flex">
                        <label className="font-small mb-2 block text-gray-700">
                          {t("user_info.PASSWORD")}
                        </label>
                        <span className="text-error">
                          {t("common.REQUIRED_FIELD_INDICATOR")}
                        </span>
                      </div>
                      <div className="relative w-full">
                        <input
                          {...register("password")}
                          type={showPassword ? "text" : "password"}
                          placeholder={t("place_holders.PASSWORD_PLACEHOLDER")}
                          onBlur={() => trigger("password")}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 flex items-center pr-3"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <EyeIcon className="h-5 w-5 text-gray-500" />
                          ) : (
                            <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                          )}
                        </button>
                      </div>
                      <p className="text-red-500">
                        {errors?.password?.message}
                      </p>
                    </div>

                    <div className="mb-4">
                      <div className="flex">
                        <label className="font-small mb-2 block text-gray-700">
                          {t("user_info.CONFIRM_PASSWORD")}
                        </label>
                        <span className="text-error">
                          {t("common.REQUIRED_FIELD_INDICATOR")}
                        </span>
                      </div>
                      <div className="relative w-full">
                        <input
                          {...register("confirm_password")}
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder={t("place_holders.CONFIRM_PASSWORD")}
                          onBlur={() => trigger("confirm_password")}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 flex items-center pr-3"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <EyeIcon className="h-5 w-5 text-gray-500" />
                          ) : (
                            <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                          )}
                        </button>
                      </div>
                      <p className="text-red-500">
                        {errors?.confirm_password?.message}
                      </p>
                    </div>

                    <div className="mb-4 flex items-center">
                      <input
                        {...register("termsAccepted")}
                        type="checkbox"
                        className="checkbox mr-2"
                      />
                      <label className="text-gray-700">
                        {t("registration.TERMS_AND_POLICY")}
                      </label>
                    </div>
                    <p className="mb-4 text-red-500">
                      {errors?.termsAccepted?.message}
                    </p>
                    <div className="flex space-x-4">
                      <button
                        className="w-full rounded bg-background-grey-300 p-3 font-semibold text-white"
                        onClick={handlePrevStep}
                      >
                        {t("common.PREV_BUTTON")}
                      </button>
                      <button
                        onClick={handleNextStep} // Make sure to pass the necessary data to this function
                        className="button-color w-full rounded p-3 font-semibold text-white"
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? (
                          <ClipLoader color="#ffffff" size={24} />
                        ) : (
                          TEXT_CONSTANTS.SIGN_UP_BUTTON
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </form>
            <div className="flex flex-col items-center justify-center">
              <p className="mt-6 text-sm font-bold">
                {t("sign_in.ALREADY_HAVE_ACCOUNT")}{" "}
                <a href="/sign-in" className="text-blue">
                  {t("common.SIGN_IN")}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {registeredSuccess ? (
        <RegisteredSuccess
          icon={<CheckIcon className="h-10 w-10 text-success"></CheckIcon>}
          button_link="/sign-in"
        />
      ) : (
        <div className="flex min-h-screen flex-col lg:flex-row">
          {/* Left Section */}
          <div className="gradient flex w-full flex-col items-start justify-between p-12 text-white lg:w-1/2">
            <div>
              <h1 className="mb-16 mt-8 text-3xl font-bold">
                {t("common.PRODUCT_TITLE")}
              </h1>
              <p className="side-heading mb-6 mt-28 pt-5 text-2xl font-semibold">
                {t("registration.SIDE_HEADING")}
                <span className="heading italic">{t("sign_in.ONE_PLACE")}</span>
              </p>
              <div className="mt-12 space-y-4 pt-12">
                <div className="relative flex flex-col space-y-8">
                  {steps.map((step, index) => (
                    <div key={index} className="flex items-center">
                      {/* Line connecting steps */}

                      {/* Step circles */}
                      <div className="relative z-10">
                        {step.status === "completed" ? (
                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                            <svg
                              className="h-5 w-5"
                              fill="none"
                              stroke="#6575EA"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div
                            className={`flex h-8 w-8 items-center justify-center rounded-full ${
                              step.status === "in-progress"
                                ? "border-2 border-gray-300 bg-white"
                                : "border-2 bg-transparent"
                            }`}
                          >
                            {step.status === "in-progress" && (
                              <div className="stepper-color h-2 w-2 rounded-full"></div>
                            )}
                          </div>
                        )}
                      </div>

                      {/* Step label */}
                      <div className="ml-4 text-2xl font-semibold text-white">
                        {step.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="align-center">
              <p className="text-md">{t("registration.SIDE_DESCRIPTION")}</p>
            </div>
          </div>

          {/* Right Section */}
          <div className="flex w-full flex-col items-center justify-center bg-white p-12 ring lg:w-1/2">
            {renderCurrentForm()}
            <div className="absolute right-3 top-1">
              <div className="flex items-center justify-between gap-2">
                <LanguageIcon width={40} height={40}></LanguageIcon>
                <LanguageChange selectClass={"p-2 text-black"}></LanguageChange>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
