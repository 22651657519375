import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import {
  EyeIcon,
  EyeSlashIcon,
  LanguageIcon,
} from "@heroicons/react/24/outline";
import apiService from "../../../services/apiService";
import helperService from "../../../services/helperService";
import { loginValidationSchema } from "../../../shared/constants/validationSchemas";
import { useTranslation } from "react-i18next";
import HeroBanner from "../../../shared/components/hero-banner/HeroBanner";
import LanguageChange from "../../../shared/components/language-change/LanguageChange";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  //show please sign in message if user is thrown for accessing resource that require logging in
  useEffect(() => {
    const showLoginMessage = localStorage.getItem("showLoginMessage");
    if(showLoginMessage){
      toast.error(t("sign_in.LOGIN_REQUEST_MSG"));
    }
    localStorage.removeItem("showLoginMessage");
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (!executeRecaptcha) {
        toast.error("ReCaptcha not loaded yet");
        return;
      }

      const token = await executeRecaptcha("login"); // You can pass an action name here
      // Conditionally add recaptcha_token if environment is not development
      const requestData =
        process.env.NODE_ENV === "development"
          ? data
          : { ...data, recaptcha_token: token };
      const response: any = await apiService.login(requestData);
      if (response.statusCode === 401 || response.statusCode === 500) {
        throw new Error(response.message);
      }
      localStorage.setItem("refreshToken", response?.data?.refreshToken);
      localStorage.setItem("accessToken", response?.data?.accessToken);
      toast.success(helperService.extractSuccessMessage(response));
      fetchApprovalStatus();
    } catch (error: any) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchApprovalStatus = async () => {
    try {
      const res: any = await apiService.getApprovalStatus();
      localStorage.setItem("isUserApproved", res.data.approval_status);
      if (res?.data?.approval_status) {
        const lastPath = localStorage.getItem("lastPath");
        if (lastPath) {
          navigate(lastPath);
          localStorage.removeItem("lastPath");
        } else navigate("/");
      } else {
        navigate("/approval-status");
      }
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  return (
    <div className="flex min-h-screen flex-col lg:flex-row">
      <HeroBanner></HeroBanner>
      <div className="flex w-full flex-col items-center justify-center p-12 lg:w-1/2">
        <div className="w-full max-w-md">
          <h2 className="mb-6 text-2xl font-bold">{t("common.SIGN_IN")}</h2>
          <form className="rounded" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <div className="flex">
                <label className="mb-2 block text-gray-700">
                  {t("user_info.EMAIL_ADDRESS")}
                </label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <input
                {...register("email")}
                type="email"
                onBlur={() => trigger("email")}
                placeholder={t("place_holders.EMAIL")}
              />
              <p className="text-red-500">{errors.email?.message}</p>
            </div>

            <div className="mb-4">
              <div className="flex">
                <label className="mb-2 block text-gray-700">
                  {t("user_info.PASSWORD")}
                </label>
                <span className="text-error">
                  {t("common.REQUIRED_FIELD_INDICATOR")}
                </span>
              </div>
              <div className="relative">
                <input
                  {...register("password")}
                  type={showPassword ? "password" : "text"}
                  placeholder={t("place_holders.PASSWORD_PLACEHOLDER")}
                  onBlur={() => trigger("password")}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label={showPassword ? "Hide password" : "Show password"}
                >
                  {showPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                  )}
                </button>
              </div>
              <p className="text-red-500">{errors.password?.message}</p>
              <div className="pt-2">
                <a
                  href="/forgot-password"
                  className="flex justify-end pt-2 text-blue-500"
                >
                  {t("place_holders.FORGOT_PASSWORD")}
                </a>
              </div>
            </div>

            <button
              type="submit"
              className="w-full rounded bg-blue-500 p-3 font-semibold text-white hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? <ClipLoader color="#ffffff" size={24} /> : "Sign In"}
            </button>
          </form>

          <div className="mt-4 flex flex-col items-center justify-center">
            <p className="text-sm font-bold">
              {t("sign_in.SIGN_UP_1")}{" "}
              <a href="/register" className="text-blue-500">
                {t("common.SIGN_UP")}
              </a>
            </p>
          </div>
          <div className="absolute right-3 top-1">
            <div className="flex items-center justify-between gap-2">
              <LanguageIcon width={40} height={40}></LanguageIcon>
              <LanguageChange selectClass={"p-2 text-black"}></LanguageChange>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
